import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {AuthToken} from './token.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  protected token$: BehaviorSubject<AuthToken> = new BehaviorSubject(null);
  protected key = 'jwt';

  constructor() {
    this.publishStoredToken();
  }

  get(): Observable<AuthToken> {
    let raw;

    if (this.parseTokenPack(localStorage.getItem(this.key)) !== null && this.unwrap(localStorage.getItem(this.key)).isValid()) {
      raw = localStorage.getItem(this.key);
    } else {
      raw = sessionStorage.getItem(this.key);
    }

    return observableOf(this.unwrap(raw));
  }

  set(token: AuthToken, rememberMe: boolean): Observable<null> {

    const raw = this.wrap(token);

    if (rememberMe) {
      localStorage.setItem(this.key, raw);
    } else {
      sessionStorage.setItem(this.key, raw);
    }

    this.publishStoredToken();
    return observableOf(null);
  }

  clear(): Observable<null> {

    localStorage.removeItem(this.key);
    sessionStorage.removeItem(this.key);

    this.publishStoredToken();
    return observableOf(null);
  }

  tokenChange(): Observable<AuthToken> {
    return this.token$.asObservable();
  }

  private publishStoredToken() {

    let raw;

    if (this.parseTokenPack(localStorage.getItem(this.key)) !== null) {
      raw = localStorage.getItem(this.key);
    } else {
      raw = sessionStorage.getItem(this.key);
    }

    this.token$.next(this.unwrap(raw));
  }

  wrap(token: AuthToken): string {
    return JSON.stringify({
      createdAt: token.getCreatedAt().getTime(),
      value: token.toString(),
    });
  }

  unwrap(value: string): AuthToken {
    let tokenValue = null;
    let tokenCreatedAt: Date = null;

    const tokenPack: TokenPack = this.parseTokenPack(value);
    if (tokenPack) {
      tokenValue = tokenPack.value;
      tokenCreatedAt = new Date(Number(tokenPack.createdAt));
    }

    return tokenValue ? new AuthToken(tokenValue, tokenCreatedAt) : null;

  }

  protected parseTokenPack(value): TokenPack {
    try {
      return JSON.parse(value);
    } catch (e) {
    }
    return null;
  }
}

export interface TokenPack {
  createdAt: number;
  value: string;
}
