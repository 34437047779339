import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {CompanyService} from '../enterprise/company/company.service';
import {UserService} from '../user/user.service';
import {UserModel} from '../user/user.model';
import {CompanyModel} from '../enterprise/company/company.model';

@Injectable()
export class CompanyGuard implements CanActivate {


  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.getUserInfo().toPromise().then((user: UserModel) => {
      if (user.company !== null && user.company.name !== null) {
        return true;
      } else {
        this.router.navigate(['/choose-company']);
        return false;
      }
    });
  }
}
