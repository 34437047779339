import {startLoadingIndicator, stopLoadingIndicator} from '@btapai/ng-loading-indicator';

import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Injectable()
export class IndicatorService {

  constructor(private snackBar: MatSnackBar) { }

  @startLoadingIndicator
  show() { }

  @stopLoadingIndicator
  hide() { }

  @stopLoadingIndicator
  error(message: string) {
    this.alert(message);
  }

  alert(message: string) {
    this.snackBar.open(message, null, { duration: 5000 });
  }
}
