import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthToken} from './token/token.model';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService,
              private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap((token: AuthToken) => {
        if (token && token.isValid()) {
          const JWT = `Bearer ${token.getValue()}`;
          req = req.clone({
            setHeaders: {
              Authorization: JWT,
            },
          });
        }
        return next.handle(req).pipe(
          catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.router.navigate(['login'], {queryParams: {from: 'sessionExpired'}});
              }
            }
            return throwError(err);
          })
        );
      }));
  }
}
