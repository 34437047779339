/**
 * PasswordChange object class with necessary attributes.
 */

export class PasswordChangeModel {

  constructor(public oldPassword: string,
              public newPassword: string) {
  }
}
