import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';

declare var process: any;

@Injectable({
    providedIn: 'root'
})
/**
 * Service used for managing environmental settings and variables.
 */
export class EnvironmentManagerService {

    /**
     * Get the type of environment this run/build is made for.
     */
    getEnviromentType(): string {
        return this.getEnvironmentVars('envType');
    }

    /**
     * Get the base URL this run/build is made for.
     */
    getUrlBase(): string {
        return environment.url;
    }

    /**
     * Get the redirect URL this run/build is made for.
     */
    getRedirectUrlBase(): string {
        return environment.redirectUrl;
    }

    /**
     * Check if this build/run is made for production.
     */
    isProduction(): boolean {
        return this.getEnvironmentVars('envType') === 'prod';
    }

    /**
     * Check if this build/run is made for development.
     */
    isDev(): boolean {
        return this.getEnvironmentVars('envType') === 'dev';
    }

    /**
     * Check if this build/run is made for testing.
     */
    isTest(): boolean {
        return this.getEnvironmentVars('envType') === 'test';
    }

    /**
     * Get environmental variables of this build/run, if they exist.
     * @param key of environmental variable specified in webpack.config.js.
     */
    private getEnvironmentVars(key: string): string {
        if (typeof process !== 'undefined' && process && process.env) {
            return process.env[key];
        } else {
            return '';
        }
    }
}
