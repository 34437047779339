import { Component } from '@angular/core';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

import * as AOS from 'aos';

import * as firebase from 'firebase';
import {LanguageService} from './shared/language.service';

var firebaseConfig = {
  apiKey: 'AIzaSyCJK_hrr3MhRHbrJO0_9XQzpKCf0YcI1eQ',
  authDomain: 'iq-document.firebaseapp.com',
  databaseURL: 'https://iq-document.firebaseio.com',
  projectId: 'iq-document',
  storageBucket: 'iq-document.appspot.com',
  messagingSenderId: '529979715396',
  appId: '1:529979715396:web:a5c652999b1e88083b3111',
  measurementId: 'G-BP1F5FJ4CK'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IQ Administration';

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private router: Router,
              public language: LanguageService) {

    AOS.init();

    this.language.init();

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        firebase.analytics().logEvent('page_view', {
          key: 'page_title',
          value: event.url
        });
      });

    this.matIconRegistry.addSvgIcon(
      'jm-software',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/jm-software.svg')
    );
  }
}
