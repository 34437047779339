import { Component, OnInit } from '@angular/core';
import {PaymentRequestModel} from '../shared/payment/payment-request.model';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../shared/payment/payment.service';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {DataService} from '../shared/data.service';
import {IndicatorService} from '../shared/indicator.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import * as moment from 'moment';
import {LanguageService} from '../shared/language.service';

const basicDescription = 'Basic, 3 users, 100 documents.';
const premiumDescription = 'Premium, 10 users, 1000 documents';
const proDescription = 'Pro, 50 users, unlimited documents.';

@Component({
  selector: 'app-choose-subscription',
  templateUrl: './choose-subscription.component.html',
  styleUrls: ['./choose-subscription.component.scss']
})
export class ChooseSubscriptionComponent implements OnInit {

  months = [1, 3, 6, 12];
  prices = [5, 9, 19];
  subscriptions = ['Basic', 'Premium', 'Pro'];
  disabled = [false, false, false];

  company: CompanyModel;

  request: PaymentRequestModel;

  totalPrice: number;

  warning: boolean;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private paymentService: PaymentService,
              private data: DataService,
              private route: ActivatedRoute,
              private indicator: IndicatorService,
              private companyService: CompanyService,
              private language: LanguageService) {
    this.data.company.subscribe(
      (company) => {
        this.company = company;
      }
    );
  }

  ngOnInit() {
    if (this.company.name === undefined) {
      this.indicator.show();
      this.companyService.getCompany().subscribe(
        (company) => {
          this.company = company;
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }

    this.route.params.subscribe(
      (params) => {
        const type = params.type;
        switch (type) {
          case 'Basic': this.request = new PaymentRequestModel('Basic', basicDescription, 1, this.language.get()); break;
          case 'Premium': this.request = new PaymentRequestModel('Premium', premiumDescription, 1, this.language.get()); break;
          case 'Pro': this.request = new PaymentRequestModel('Pro', proDescription, 1, this.language.get()); break;
          default: this.request = new PaymentRequestModel(undefined, undefined, 1, this.language.get());
        }
        this.calculateTotalPrice();
      }
    );

    if (!this.company.subscription.expired && !(this.company.subscription.dueDate < new Date())) {
      switch (this.company.subscription.type.name) {
        case 'Basic': this.disabled = [true, false, false]; break;
        case 'Premium': this.disabled = [true, true, false]; break;
        case 'Pro': this.disabled = [true, true, true]; break;
        default: this.disabled = [false, false, false];
      }
    }
  }

  getNumberOfUsers(type: string): number {
    switch (type) {
      case 'Basic': return 3;
      case 'Premium': return 10;
      case 'Pro': return 50;
      default: break;
    }
  }

  getNumberOfDocuments(type: string) {
    switch (type) {
      case 'Basic': return 100;
      case 'Premium': return 1000;
      case 'Pro': return 'unlimited';
      default: break;
    }
  }

  onChangeName(value: string) {
    this.request.name = value;
    this.calculateTotalPrice();
  }

  onChangeMonths(value: number) {
    this.request.months = value;
    this.calculateTotalPrice();
  }

  calculateTotalPrice() {

    if (this.company.subscription != null && this.company.subscription.type.name !== 'Free') {
      const monthsLeft = moment(this.company.subscription.dueDate).month() - (new Date()).getMonth() +
        (12 * (moment(this.company.subscription.dueDate).year() - (new Date()).getFullYear()));

      const priceDifference = this.prices[this.subscriptions.indexOf(this.request.name)] -
        parseFloat(this.company.subscription.type.amount.value);

      if (priceDifference < 0) {
        this.warning = false;
        return;
      }

      if (monthsLeft <= this.request.months) {
        this.totalPrice = monthsLeft * priceDifference +
          (this.request.months - monthsLeft) * this.prices[this.subscriptions.indexOf(this.request.name)];
        this.warning = false;
      } else {
        this.totalPrice = this.request.months * priceDifference;
        this.warning = true;
      }
    } else {
      this.totalPrice = this.prices[this.subscriptions.indexOf(this.request.name)] * this.request.months;
      this.warning = false;
    }
  }

  getLink() {

    if (!this.request.description || this.request.description.length === 0) {
      this.indicator.error('Please choose a subscription type'); return;
    }

    switch (this.request.name) {
      case 'Basic': this.request.description = basicDescription; break;
      case 'Premium': this.request.description = premiumDescription; break;
      case 'Pro': this.request.description = proDescription; break;
      default: this.indicator.error('Please choose a subscription type'); return;
    }

    if (!this.request.months || !this.months.includes(+this.request.months)) {
      this.indicator.error('Please choose a number of months.'); return;
    }

    this.indicator.show();

    this.authService.isAuthenticated().then(
      (bool) => {
        if (bool) {

          this.paymentService.getPaymentUrl(this.request).subscribe(
            (payment) => {
              this.indicator.hide();
              localStorage.setItem('paymentId', payment.id);
              window.location.href = payment.url;
            }, () => {
              this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
            });
        } else {
          this.indicator.hide();
          this.router.navigate(['/login']);
        }
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }
}
