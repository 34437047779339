import { Component, OnInit } from '@angular/core';
import {IndicatorService} from '../shared/indicator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordResetModel} from '../shared/user/password/password-reset.model';
import {UserService} from '../shared/user/user.service';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordReset: PasswordResetModel;

  newPassword: string;

  constructor(private indicator: IndicatorService,
              private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private language: LanguageService) {
    this.route.params.subscribe(
      (params) =>
        this.passwordReset = new PasswordResetModel(params.username, params.token, undefined)
    );
  }

  ngOnInit() {
    console.log(this.passwordReset);
  }

  resetPassword() {

    if (!this.passwordReset.newPassword || this.passwordReset.newPassword.length < 8) {
      this.language.localize('indicator.alert.messageInvalidPassword').subscribe((text) => this.indicator.error(text));
      return;
    }

    if (this.passwordReset.newPassword !== this.newPassword) {
      this.language.localize('indicator.alert.messagePasswordDoNotMatch').subscribe((text) => this.indicator.error(text));
      return;
    }

    this.indicator.show();

    this.userService.resetPassword(this.passwordReset).subscribe(
      () => {
        this.indicator.hide();
        this.language.localize('indicator.alert.messagePasswordChanged').subscribe(
          (text) => {
            this.indicator.alert(text);
            this.router.navigate(['/login']);
          });
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }
}
