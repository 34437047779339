import {UserModel} from './user.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentManagerService} from '../environment/environment.service';
import {Observable} from 'rxjs';
import {CompanyService} from '../enterprise/company/company.service';
import {CompanyModel} from '../enterprise/company/company.model';
import {PasswordForgotModel} from './password/password-forgot.model';
import {PasswordResetModel} from './password/password-reset.model';
import {PasswordChangeModel} from './password/password-change.model';

const updateUrl = '/user';
const myInfoUrl = '/user/me';
const forgotPasswordUrl = '/user/forgot-password';
const resetPasswordUrl = '/user/reset-password';
const changePasswordUrl = '/user/change-password';
const acceptInvitationUrl = '/company/accept/';
const declineInvitationUrl = '/company/decline/';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for actions concerning UserModel objects.
 */
export class UserService {

    /**
     * Base URL for making request to the backend.
     */
    baseUrl: string;

    /**
     * Injecting services and initial setup.
     */
    constructor(private http: HttpClient,
                private env: EnvironmentManagerService,
                private companyService: CompanyService) {

        this.baseUrl = env.getUrlBase();
    }

    /**
     * Update user info.
     */
    updateUserInfo(user: UserModel): Observable<any> {
        return this.http.put(this.baseUrl + updateUrl, user);
    }

    /**
     * Get user info.
     */
    getUserInfo(): Observable<any> {
        return this.http.get(this.baseUrl + myInfoUrl);
    }

    /**
     * Request email with token when user forgets password.
     */
    forgotPassword(passwordForgot: PasswordForgotModel): Observable<any> {
      return this.http.post(this.baseUrl + forgotPasswordUrl, passwordForgot);
    }

    /**
     * Reset password with new password.
     */
    resetPassword(passwordReset: PasswordResetModel): Observable<any> {
      return this.http.post(this.baseUrl + resetPasswordUrl, passwordReset);
    }

    /**
     * Change password to new one.
     */
    changePassword(passwordChange: PasswordChangeModel): Observable<any> {
      return this.http.post(this.baseUrl + changePasswordUrl, passwordChange);
    }

    /**
     * Accept company invitation.
     */
    acceptInvitation(request: CompanyModel): Observable<any> {
        return this.http.put(this.baseUrl + acceptInvitationUrl + request.id, null);
    }

    /**
     * Decline company invitation.
     */
    declineInvitation(request: CompanyModel): Observable<any> {
        return this.http.put(this.baseUrl + declineInvitationUrl + request.id, null);
    }
}
