import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../shared/user/user.service';
import {DataService} from '../shared/data.service';
import {UserModel} from '../shared/user/user.model';
import {IndicatorService} from '../shared/indicator.service';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-choose-company',
  templateUrl: './choose-company.component.html',
  styleUrls: ['./choose-company.component.scss']
})
export class ChooseCompanyComponent implements OnInit {

  user: UserModel;

  constructor(private router: Router,
              private userService: UserService,
              private data: DataService,
              private indicator: IndicatorService,
              private language: LanguageService) {
    this.data.me.subscribe(
      (user) => {
        this.user = user;
      }
    );
  }

  ngOnInit() {
    if (this.user.username === undefined) {
      this.userService.getUserInfo().subscribe(
        (user) => {
          this.user = user;
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }
  }

  acceptInvitation(request: CompanyModel) {
    this.indicator.show();
    this.userService.acceptInvitation(request).subscribe(
      () => {
        this.userService.getUserInfo().subscribe(
          (user: UserModel) => {
            this.data.me.next(user);
            this.language.localize('indicator.alert.joined').subscribe(
              (text) => {
                this.indicator.alert(text + request.name);
                this.router.navigate(['/account']).then(() => this.indicator.hide());
              }
            );
          }
        );
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }

  declineInvitation(request: CompanyModel) {
    this.userService.declineInvitation(request).subscribe(
      () => {
        this.user.invitations = this.user.invitations.filter(
          (invitation) => request.id !== invitation.id);
        this.data.me.next(this.user);
        this.language.localize('indicator.alert.titleDeclineSucceed').subscribe(
          (text) => {
            this.indicator.alert(text);
          }
        );
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }
}
