import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../user/user.service';
import {UserModel} from '../user/user.model';

@Injectable()
export class NoCompanyGuard implements CanActivate {


  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.getUserInfo().toPromise().then((user: UserModel) => {
      if (user.company === null || user.company.name === null) {
        return true;
      } else {
        this.router.navigate(['/account']);
        return false;
      }
    });
  }
}
