/**
 * Address object class with necessary attributes.
 */
export class AddressModel {

    country = '';
    street = '';
    number = '';
    postalCode = '';
    city = '';

    constructor() {

    }

    allFieldsAreFilledIn(): boolean {
        return !(this.city == undefined || this.city == '' || this.country == undefined || this.country == '' || this.street == undefined || this.street == '' || this.number == undefined || this.number == '' || this.postalCode == undefined || this.postalCode == '');
    }
}
