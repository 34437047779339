import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const intendedRoute = next.url.pop().path;

    const routes = ['Basic', 'Premium', 'Pro'];

    return this.authService.isAuthenticated().then(
      (bool) => {
        if (bool) {
          return true;
        } else {
          if (routes.includes(intendedRoute)) {
            this.router.navigate(['/login', 'pricing']);
            return false;
          } else {
            this.router.navigate(['/login', intendedRoute]);
            return false;
          }
        }
      }
    );
  }

}
