/**
 * PasswordForgot object class with necessary attributes.
 */

export class PasswordForgotModel {

  constructor(private username: string,
              private language: string) {
  }
}
