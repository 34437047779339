import {Injectable} from '@angular/core';
import {CompanyModel} from './company.model';
import {HttpClient} from '@angular/common/http';
import {EnvironmentManagerService} from '../../environment/environment.service';
import {Observable} from 'rxjs';

const registerCompanyUrl = '/company/register';
const leaveCompany = '/company/leave';
const inviteUserForCompany = '/company/invite/';
const deleteInvitationForCompany = '/company/invite/delete';
const deleteUserFromCompany = '/company/delete/';
const updateCompany = '/company/';
const myCompanyInfoUrl = '/company';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for actions concerning CompanyModel objects.
 */
export class CompanyService {

    /**
     * Base URL for making request to the backend.
     */
    baseUrl: string;

    /**
     * Injecting services and initial setup.
     * @param http
     * @param env
     */
    constructor(private http: HttpClient,
                private env: EnvironmentManagerService) {

        this.baseUrl = env.getUrlBase();
    }

    /**
     * Get company info of company user is part of.
     */
    getCompany(): Observable<any> {

        return this.http.get(this.baseUrl + myCompanyInfoUrl);
    }

    /**
     * Register function.
     * @param company
     */
    register(company: CompanyModel): Observable<any> {

        return this.http.post(this.baseUrl + registerCompanyUrl, company, {observe: 'response'});
    }

    /**
     * Update company info.
     * @param id
     * @param company
     */
    updateCompany(id: number, company: CompanyModel) {

        return this.http.put(this.baseUrl + updateCompany + id, company);
    }

    /**
     * Invite an user to join your company.
     * @param username
     */
    inviteUser(username: string) {

        return this.http.put(this.baseUrl + inviteUserForCompany + username, null);
    }

    /**
     * Delete an request to an user to join your company.
     * @param username
     */
    deleteRequest(username: string) {

        return this.http.put(this.baseUrl + deleteInvitationForCompany + username, null);
    }

    /**
     * Delete an user from a company.
     * @param username
     */
    deleteUser(username: string) {

        return this.http.put(this.baseUrl + deleteUserFromCompany + username, null);
    }

    /**
     * Delete user from company.
     */
    leaveCompany() {

      return this.http.put(this.baseUrl + leaveCompany, null);
    }
}
