import { Component, OnInit } from '@angular/core';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {IndicatorService} from '../shared/indicator.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {AddressModel} from '../shared/enterprise/address.model';
import {DataService} from '../shared/data.service';
import {Router} from '@angular/router';
import {LanguageService} from '../shared/language.service';

/**
 * Regex expression used to validate email address input.
 */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {

  company: CompanyModel;

  image: SafeUrl = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  selectedFile: File;

  agreeToTerms = false;

  constructor(private sanitizer: DomSanitizer,
              private indicator: IndicatorService,
              private companyService: CompanyService,
              private data: DataService,
              private router: Router,
              private language: LanguageService) {
    this.company = new CompanyModel(new AddressModel());
    this.company.logo = 'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }

  ngOnInit() {
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size / 1024 / 1024 > 1) {
      this.language.localize('indicator.alert.messageFileTooBig').subscribe((text) => this.indicator.error(text));
    } else {
      this.fileToBase64();
    }
  }

  fileToBase64() {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        const b64: string = reader.result;
        this.company.logo = b64.replace(/^data:.+;base64,/, '');
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.company.logo);
      }
    };

    reader.readAsDataURL(this.selectedFile);
  }

  register() {

    this.indicator.show();

    // One or more of the fields are empty.
    if (!this.company.allFieldsAreFilledIn() || !this.company.address.allFieldsAreFilledIn()) {
      this.language.localize('indicator.alert.messageEmptyFields').subscribe((text) => this.indicator.error(text));

      return;
    }

    // Email address is not a valid email address.
    if (!emailRegex.test(this.company.emailAddress)) {
      this.language.localize('indicator.alert.messageInvalidEmailAddress').subscribe((text) => this.indicator.error(text));

      return;
    }

    if (!this.agreeToTerms) {
      this.language.localize('indicator.alert.messageAcceptConditions').subscribe((text) => this.indicator.error(text));
      return;
    }

    this.companyService.register(this.company).subscribe(() => {
      this.companyService.getCompany().subscribe(
        (company: CompanyModel) => {
          this.data.company.next(company);
          this.router.navigate(['/register-company-confirmation']).then(() => this.indicator.hide());
        }
      );
    }, () => {
      this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
    });
  }
}
