import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/data.service';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {IndicatorService} from '../shared/indicator.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {UserService} from '../shared/user/user.service';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-subscription-expired',
  templateUrl: './subscription-expired.component.html',
  styleUrls: ['./subscription-expired.component.scss']
})
export class SubscriptionExpiredComponent implements OnInit {

  owner: boolean;
  company: CompanyModel;

  constructor(private data: DataService,
              private indicator: IndicatorService,
              private companyService: CompanyService,
              private userService: UserService,
              private language: LanguageService) {
    this.data.company.subscribe(
      (company) => {
        this.company = company;
        this.data.me.subscribe(
          (user) => {
            this.owner = user.username === company.owner.username;
          }
        );
      }
    );
  }

  ngOnInit() {
    if (this.company.name === undefined) {
      this.indicator.show();
      this.companyService.getCompany().subscribe(
        (company) => {
          this.company = company;
          this.userService.getUserInfo().subscribe(
            (user) => {
              this.owner = user.username === company.owner.username;
              this.indicator.hide();
            }, () => {
              this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
            });
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }
  }

}
