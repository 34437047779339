import {environment} from 'src/environments/environment';

/**
 * Payment object class with necessary attributes.
 */
export class PaymentRequestModel {

  private redirectUrl = environment.redirectUrl;

  constructor(public name: string,
              public description: string,
              public months: number,
              public language: string) {
  }
}
