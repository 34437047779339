import { Component, OnInit } from '@angular/core';
import {IndicatorService} from '../shared/indicator.service';
import {UserService} from '../shared/user/user.service';
import {Router} from '@angular/router';
import {PasswordChangeModel} from '../shared/user/password/password-change.model';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordChange: PasswordChangeModel;

  newPassword: string;

  constructor(private indicator: IndicatorService,
              private userService: UserService,
              private router: Router,
              private language: LanguageService) {
    this.passwordChange = new PasswordChangeModel(undefined, undefined);
  }

  ngOnInit() {

  }

  changePassword() {

    if (!this.passwordChange.oldPassword || this.passwordChange.oldPassword.length < 8) {
      this.language.localize('indicator.alert.messageInvalidPassword').subscribe(
        (text) => this.indicator.alert(text)
      );
      return;
    }

    if (!this.passwordChange.newPassword || this.passwordChange.newPassword.length < 8) {
      this.language.localize('indicator.alert.messageInvalidPassword').subscribe(
        (text) => this.indicator.alert(text)
      );
      return;
    }

    if (this.passwordChange.newPassword !== this.newPassword) {
      this.language.localize('indicator.alert.messagePasswordDoNotMatch').subscribe(
        (text) => this.indicator.alert(text)
      );
      return;
    }

    this.indicator.show();

    this.userService.changePassword(this.passwordChange).subscribe(
      () => {
        this.indicator.hide();
        this.language.localize('indicator.alert.messagePasswordChanged').subscribe(
          (text) => {
            this.indicator.alert(text);
            this.router.navigate(['/account']);
          }
        );
      }, () => this.language.localize('indicator.alert.messageOldPasswordIncorrect').subscribe(
        (text) => this.indicator.error(text)
      )
    );
  }
}
