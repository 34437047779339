import {Injectable} from '@angular/core';
import {InvoiceModel} from './invoice.model';
import {HttpClient} from '@angular/common/http';
import {EnvironmentManagerService} from '../environment/environment.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const registerInvoiceUrl = '/document/invoice/new';
const allInvoicesUrl = '/document/invoice/all';
const updateInvoice = '/document/invoice/';
const deleteInvoice = '/document/invoice/';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for actions concerning InvoiceModel objects.
 */
export class InvoiceService {

    /**
     * Injecting services and initial setup.
     * @param http
     * @param env
     */
    constructor(private http: HttpClient,
                private env: EnvironmentManagerService) {

        this.baseUrl = env.getUrlBase();
    }

    /**
     * Base URL for making request to the backend.
     */
    baseUrl: string;

    /**
     * Parse incoming JSON into InvoiceModels
     * @param invoices
     */
    private static parseAllInvoices(invoices: any): Array<InvoiceModel> {

        const parsedInvoices: Array<InvoiceModel> = new Array<InvoiceModel>();

        for (const invoice of invoices) {

            parsedInvoices.push(invoice);
        }

        return parsedInvoices;
    }

    /**
     * Get list of all invoices.
     */
    getAllInvoices(): Observable<Array<InvoiceModel>> {

        return this.http.get(this.baseUrl + allInvoicesUrl)
            .pipe(map(
                (data) => {
                    return InvoiceService.parseAllInvoices(data);
                }
            ));
    }

    /**
     * Create invoice function.
     * @param invoice
     */
    createNewInvoice(invoice: InvoiceModel): Observable<any> {

        return this.http.post(this.baseUrl + registerInvoiceUrl, invoice, {observe: 'response'})
            .pipe(map(
                (data) => {
                    return data.headers.get('Location').split('/').pop();
                }
            ));
    }

    /**
     * Update invoice info.
     * @param id
     * @param invoice
     */
    updateInvoice(id: number, invoice: InvoiceModel) {

        return this.http.put(this.baseUrl + updateInvoice + id, invoice);
    }

    /**
     * Delete an invoice.
     * @param id
     */
    deleteInvoice(id: number) {

        return this.http.delete(this.baseUrl + deleteInvoice + id);
    }
}
