import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
  })
export class LanguageService {

  language = 'en';

  constructor(private translate: TranslateService) {

  }

  init() {
    this.language = this.getUsersLocale(this.language);
    this.translate.use(this.language);
  }

  switch(language: string) {
    this.language = language;
    this.translate.use(this.language);
  }

  localize(key: string) {
    return this.translate.get(key);
  }

  get() {
    return this.translate.currentLang.split('-')[0];
  }

  getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;

    const result = lang.split('-')[0];

    if (result === 'en' || result === 'nl') {
      return result;
    } else {
      return 'en';
    }
  }
}
