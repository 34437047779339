import { Component, OnInit } from '@angular/core';
import {PaymentService} from '../shared/payment/payment.service';
import {DataService} from '../shared/data.service';
import {PaymentStatusModel} from '../shared/payment/payment-status.model';
import {IndicatorService} from '../shared/indicator.service';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-finish-payment',
  templateUrl: './finish-payment.component.html',
  styleUrls: ['./finish-payment.component.scss']
})
export class FinishPaymentComponent implements OnInit {

  paymentStatus: PaymentStatusModel = new PaymentStatusModel('loading...');

  constructor(private paymentService: PaymentService,
              private data: DataService,
              private indicator: IndicatorService,
              private language: LanguageService) {
  }

  ngOnInit() {
    const id = localStorage.getItem('paymentId');
    this.paymentService.getPaymentStatus(id).subscribe(
      (dat) => {
        this.paymentStatus.status = dat.status;
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }

}
