import { Component, OnInit } from '@angular/core';
import {UserModel} from '../shared/user/user.model';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {Router} from '@angular/router';
import {IndicatorService} from '../shared/indicator.service';
import {LanguageService} from '../shared/language.service';

/**
 * Regex expression used to validate email address input.
 */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  /**
   * UserModel object to represent the users credentials.
   */
  user: UserModel;

  /**
   * Second password textfield.
   */
  secondPassword: string;

  agreeToTerms = false;

  /**
   * Inject services and do some setup.
   */
  constructor(private authService: AuthenticationService,
              private router: Router,
              private indicator: IndicatorService,
              private language: LanguageService) {
    this.user = new UserModel(this.language.get());
  }

  /**
   * Initializing setup.
   */
  ngOnInit() {

  }

  /**
   * Login function when user hits login button.
   */
  register() {
    this.indicator.show();

    this.user.language = this.language.get();

    // One or more of the fields are empty.
    if (!this.user.allFieldsFilledIn()) {
      this.language.localize('indicator.alert.messageEmptyFields').subscribe((text) => this.indicator.error(text));
      return;
    }

    // Username is not a valid.
    if (this.user.username.length > 15 || this.user.username.includes(' ')) {
      this.language.localize('indicator.alert.messageInvalidUsername').subscribe((text) => this.indicator.error(text));
      return;
    }

    // Password is not a valid.
    if (this.user.password.length < 8 || this.user.password.length > 15 || this.user.password.includes(' ')) {
      this.language.localize('indicator.alert.messageInvalidPassword').subscribe((text) => this.indicator.error(text));
      return;
    }

    // Email address is not a valid email address.
    if (!emailRegex.test(this.user.emailAddress)) {
      this.language.localize('indicator.alert.messageInvalidEmailAddress').subscribe((text) => this.indicator.error(text));
      return;
    }

    // Passwords do not match.
    if (this.user.password !== this.secondPassword) {
      this.language.localize('indicator.alert.messagePasswordDoNotMatch').subscribe((text) => this.indicator.error(text));
      return;
    }

    if (!this.agreeToTerms) {
      this.language.localize('indicator.alert.messageAcceptConditions').subscribe((text) => this.indicator.error(text));
      return;
    }

    this.authService.register(this.user)
      .subscribe(
        () => {
          this.indicator.hide();
          this.navigateToConfirm();
        }, (error) => {
          if (error.status === 400) {
            this.language.localize('indicator.alert.messageUserAlreadyExists').subscribe((text) => this.indicator.error(text));
          } else {
            this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
          }
        });
  }

  navigateToConfirm() {
    this.router.navigate(['/register-confirmation']);
  }
}
