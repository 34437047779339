import { Component, OnInit } from '@angular/core';
import {UserModel} from '../shared/user/user.model';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {DataService} from '../shared/data.service';
import {UserService} from '../shared/user/user.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {IndicatorService} from '../shared/indicator.service';
import {LanguageService} from '../shared/language.service';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {

  collapsed = true;
  isAuthenticated = false;

  user: UserModel = new UserModel(this.language.get());
  oldUser: UserModel = new UserModel(this.language.get());
  image: SafeUrl = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  company: CompanyModel;

  constructor(private data: DataService,
              private userService: UserService,
              private companyService: CompanyService,
              private authService: AuthenticationService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private indicator: IndicatorService,
              public language: LanguageService) {
    this.data.me.subscribe(
      (user) => {
        this.user = user;
        this.oldUser = user;
      }
    );
    this.data.company.subscribe(
      (company) => {
        this.company = company;
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.company.logo);
      }
    );
    this.authService.isAuthenticated().then(
      (result) => this.isAuthenticated = result
    );
  }

  ngOnInit() {
    if (this.user.username === undefined) {
      this.userService.getUserInfo().subscribe(
        (user: UserModel) => {
          this.user = user;
          this.oldUser = user;

          if (user.company !== null && user.company.name !== null) {
            this.companyService.getCompany().subscribe(
              (company) => {
                this.company = company;
                this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.company.logo);
              }, () => {
                this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
              });
          }
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }
  }

  save(form: NgForm) {
    this.indicator.show();

    // One or more of the fields are empty.
    if (!this.allFieldsFilledIn(this.user)) {
      this.language.localize('indicator.alert.messageEmptyFields').subscribe((text) => this.indicator.error(text));
      return;
    }

    // Email address is not a valid email address.
    if (!emailRegex.test(this.user.emailAddress)) {
      this.language.localize('indicator.alert.messageInvalidEmailAddress').subscribe((text) => this.indicator.error(text));
      return;
    }

    this.userService.updateUserInfo(this.user).subscribe(
      () => {
        this.data.me.next(this.user);
        form.form.markAsPristine();
        this.indicator.hide();
        this.language.localize('indicator.alert.messageUserSaved').subscribe((text) => this.indicator.alert(text));
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  signIn() {
    this.router.navigate(['/login']);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  allFieldsFilledIn(user: UserModel): boolean {
    return !(user.username === undefined || user.username === '' || user.emailAddress === undefined || user.emailAddress === '' || user.firstName === undefined || user.firstName === '' || user.lastName === undefined || user.lastName === '');
  }
}
