import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/data.service';
import {UserService} from '../shared/user/user.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {Router} from '@angular/router';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  collapsed = true;
  isAuthenticated = false;

  tab = 1;

  constructor(private data: DataService,
              private userService: UserService,
              private companyService: CompanyService,
              private authService: AuthenticationService,
              private router: Router,
              public language: LanguageService) {
    this.authService.isAuthenticated().then(
      (result) => this.isAuthenticated = result
    );
  }

  ngOnInit() {

  }

  toggleInfo(tab: number) {
    this.tab = tab;
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  signIn() {
    this.router.navigate(['/login']);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
}
