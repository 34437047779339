import {UserModel} from '../../user/user.model';
import {TagModel} from '../../document/tag.model';
import {AddressModel} from '../address.model';
import {SubscriptionModel} from '../../subscription/subscription.model';
import {CustomerModel} from '../customer/customer.model';

/**
 * Company object class with necessary attributes.
 */
export class CompanyModel {

  id: number;

  name: string;
  emailAddress: string;
  telephone: string;
  logo: string;
  vatCode: string;
  cocNumber: string;
  bankAccount: string;

  owner: UserModel;

  tags: Array<TagModel>;
  users: Array<UserModel>;
  requests: Array<UserModel>;
  customers: Array<CustomerModel>;

  subscription: SubscriptionModel;

  constructor(public address: AddressModel, company?: CompanyModel) {
    if (company) {
      this.id = company.id;
      this.name = company.name;
      this.emailAddress = company.emailAddress;
      this.telephone = company.telephone;
      this.logo = company.logo;
      this.vatCode = company.vatCode;
      this.cocNumber = company.cocNumber;
      this.bankAccount = company.bankAccount;
      this.users = company.users;
      this.requests = company.requests;
      this.address = company.address;
    }
  }

  allFieldsAreFilledIn(): boolean {
    return !(this.name === undefined || this.name === '' || this.emailAddress === undefined || this.emailAddress === '' || this.telephone === undefined || this.telephone === '');
  }
}
