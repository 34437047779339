/**
 * PasswordChange object class with necessary attributes.
 */

export class PasswordResetModel {

  constructor(private username: string,
              private token: string,
              public newPassword: string) {
  }
}
