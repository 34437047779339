import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {CompanyService} from '../enterprise/company/company.service';
import {UserService} from '../user/user.service';
import {UserModel} from '../user/user.model';
import {CompanyModel} from '../enterprise/company/company.model';

@Injectable()
export class OwnerGuard implements CanActivate {


  constructor(private companyService: CompanyService, private userService: UserService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     if (this.companyService.getCompany().toPromise().then(
       (company: CompanyModel) => {
         return this.userService.getUserInfo().toPromise().then(
           (user: UserModel) => {
             return user.username === company.owner.username;
           }
         );
       }
     )) {
       return true;
     } else {
       this.router.navigate(['/not-owner']);
     }
  }
}
