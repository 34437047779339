import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {Router} from '@angular/router';
import {PaymentService} from '../shared/payment/payment.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {LanguageService} from '../shared/language.service';

const redirectUrl = 'https://iq-administration.jm-software.nl/finish-payment';

const basicDescription = 'Basic subscription for one month, maximum of 3 users allowed.';
const premiumDescription = 'Premium subscription for one month, maximum of 10 users allowed.';
const proDescription = 'Pro subscription for one month, maximum of 50 users allowed.';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  isAuthenticated = false;
  collapsed = true;

  upgradePossible: boolean;

  upgradePossibleBasic = true;
  upgradePossiblePremium = true;
  upgradePossiblePro = true;

  status1 = false;
  status2 = false;
  status3 = false;
  status4 = false;
  status5 = false;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private paymentService: PaymentService,
              private companyService: CompanyService,
              public language: LanguageService) {
    this.authService.isAuthenticated().then(
      (result) => this.isAuthenticated = result
    );
  }

  ngOnInit() {
    if (this.isAuthenticated) {
      this.companyService.getCompany().subscribe(
        (company) => {
          if (company.subscription
            && company.subscription.type.name !== 'Free'
            && !company.subscription.expired
            && !(company.subscription.dueDate < new Date())) {
            this.upgradePossible = true;
            switch (company.subscription.type.name) {
              case 'Basic': this.upgradePossibleBasic = false; break;
              case 'Premium': this.upgradePossibleBasic = false; this.upgradePossiblePremium = false; break;
              case 'Pro': this.upgradePossibleBasic = false; this.upgradePossiblePremium = false; this.upgradePossiblePro = false;  break;
            }
          }
        }
      );
    }
  }

  toChooseSubscription(subscriptionType: string) {
    this.router.navigate(['/choose-subscription', subscriptionType]);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  signIn() {
    this.router.navigate(['/login']);
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  toggleStatus1() {
    this.status1 = !this.status1;
  }

  toggleStatus2() {
    this.status2 = !this.status2;
  }

  toggleStatus3() {
    this.status3 = !this.status3;
  }

  toggleStatus4() {
    this.status4 = !this.status4;
  }

  toggleStatus5() {
    this.status5 = !this.status5;
  }
}
