import { Component, OnInit } from '@angular/core';
import {IndicatorService} from '../shared/indicator.service';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {Router} from '@angular/router';
import {UserService} from '../shared/user/user.service';
import {PasswordForgotModel} from '../shared/user/password/password-forgot.model';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  emailAddress: string;
  successful = false;

  constructor(private indicator: IndicatorService,
              private authService: AuthenticationService,
              private router: Router,
              private userService: UserService,
              private language: LanguageService) {
  }

  ngOnInit() {
  }

  forgotPassword() {
    this.indicator.show();

    this.userService.forgotPassword(new PasswordForgotModel(this.emailAddress, this.language.get())).subscribe(
      () => {
        this.indicator.hide();
        this.successful = true;
      }, () => this.language.localize('indicator.alert.messageUserDoesNotExist').subscribe((text) => this.indicator.error(text))
    );
  }
}
