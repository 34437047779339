import { Component, OnInit } from '@angular/core';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {UserModel} from '../shared/user/user.model';
import {DomSanitizer} from '@angular/platform-browser';
import {DataService} from '../shared/data.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {UserService} from '../shared/user/user.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {IndicatorService} from '../shared/indicator.service';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss']
})
export class CompanyUsersComponent implements OnInit {

  collapsed = true;
  isAuthenticated = false;

  company: CompanyModel;
  currentUser: UserModel = new UserModel(this.language.get());

  owner: boolean;
  username: string;

  constructor(private data: DataService,
              private companyService: CompanyService,
              private userService: UserService,
              private router: Router,
              private authService: AuthenticationService,
              private sanitizer: DomSanitizer,
              private indicator: IndicatorService,
              public language: LanguageService) {
    this.data.company.subscribe(
      (company) => {
        this.company = company;
        this.data.me.subscribe(
          (user) => {
            this.currentUser = user;
            this.owner = user.username === company.owner.username;
          }
        );
      }
    );
    this.authService.isAuthenticated().then(
      (result) => this.isAuthenticated = result
    );
  }

  ngOnInit() {
    if (this.company.name === undefined) {
      this.indicator.show();
      this.companyService.getCompany().subscribe(
        (company) => {
          this.company = company;
          this.userService.getUserInfo().subscribe(
            (user) => {
              this.currentUser = user;
              this.owner = user.username === company.owner.username;
              this.indicator.hide();
            }, () => {
              this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
            });
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }
  }

  invite(username: string) {
    this.indicator.show();
    this.companyService.inviteUser(username).subscribe(
      () => {
        this.indicator.hide();
        this.language.localize('indicator.alert.messageInvited').subscribe(
          (text) => {
            this.indicator.alert(text + username);
          }
        );
        this.username = undefined;
      }, (error) => {
        this.username = undefined;

        if (error.status === 402) {
          this.language.localize('indicator.alert.messageUserLimit').subscribe((text) => this.indicator.error(text));
        } else {
          this.language.localize('indicator.alert.messageNotInvited').subscribe((text) => this.indicator.error(text));
        }
      }
    );
  }

  deleteUser(user: UserModel) {
    this.indicator.show();
    this.companyService.deleteUser(user.username).subscribe(
      () => {
        this.company.users = this.company.users.filter(
          (us) => us.username !== user.username
        );
        this.data.company.next(this.company);
        this.indicator.hide();
        this.language.localize('indicator.alert.userDeleted').subscribe(
          (text) => {
            this.indicator.alert(text + user.username);
          }
        );
      }, () => {
        this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
      });
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  signIn() {
    this.router.navigate(['/login']);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
}
