import {Component, NgZone, OnInit} from '@angular/core';
import {LoginModel} from '../shared/authentication/login.model';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {InvoiceService} from '../shared/document/invoice.service';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {UserService} from '../shared/user/user.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {EnvironmentManagerService} from '../shared/environment/environment.service';
import {DataService} from '../shared/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {IndicatorService} from '../shared/indicator.service';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    /**
     * UserModel object to represent the users credentials.
     */
    user: LoginModel;

    /**
     * Color of the text fields to indicate valid input.
     */
    usernameTextFieldColor = '#E6EAEB';
    passwordTextFieldColor = '#E6EAEB';

    /**
     * Indicates if data is being loaded.
     */
    userInfoIsLoading: boolean;
    companyInfoIsLoading: boolean;
    invoicesAreLoading = false;

    /**
     * Inject services and do some setup.
     */
    constructor(private userService: UserService,
                private routerExtension: Router,
                private authService: AuthenticationService,
                private environmentService: EnvironmentManagerService,
                private data: DataService,
                private companyService: CompanyService,
                private invoiceService: InvoiceService,
                private snackBar: MatSnackBar,
                private indicator: IndicatorService,
                private language: LanguageService,
                private route: ActivatedRoute) {
    }

    /**
     * Initializing setup.
     */
    ngOnInit() {
      this.user = AuthenticationService.getRememberLogin();
    }

    /**
     * Login function when user hits login button.
     */
    login() {

      this.indicator.show();

      // Username or password is empty.
      if (this.user.username === undefined || this.user.password === undefined ||
        this.user.username.length === 0 || this.user.password.length === 0) {

        if (this.user.username === undefined) {
          this.usernameTextFieldColor = '#A73B3A';
        }

        if (this.user.password === undefined) {
          this.passwordTextFieldColor = '#A73B3A';
        }

        this.indicator.hide();
        this.language.localize('indicator.alert.messageEmptyFields').subscribe((text) => this.indicator.alert(text));

        return;
      }

      // Attempt login.
      this.authService.login(this.user).subscribe(() => {
        this.userInfoIsLoading = true;
        this.companyInfoIsLoading = true;
        this.invoicesAreLoading = true;

        this.userService.getUserInfo().subscribe(
          (user) => {
            this.data.me.next(user);
            this.userInfoIsLoading = false;

            if (user.company !== null) {
              this.companyService.getCompany().subscribe(
                (company: CompanyModel) => {
                  this.data.company.next(company);
                  this.companyInfoIsLoading = false;
                  this.hideIndicatorAndNavigate();
                }, () => {
                  this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
                });
              this.invoiceService.getAllInvoices().subscribe(
                (invoices) => {
                  this.data.invoices.next([...invoices.sort((a, b) => {
                    if (a.lastModifiedDate < b.lastModifiedDate) { return 1; } else if (a.lastModifiedDate > b.lastModifiedDate) { return -1; } else { return 0; }
                  })]);
                  this.invoicesAreLoading = false;
                  this.hideIndicatorAndNavigate();
                }, () => {
                  this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
                });
            } else {
              this.invoicesAreLoading = false;
              this.companyInfoIsLoading = false;
              this.hideIndicatorAndNavigate();
            }
          }, () => {
            this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
          });

      }, () => {
        this.language.localize('indicator.alert.messageInvalidCredentials').subscribe((text) => this.indicator.error(text));
        this.user.username = '';
        this.user.password = '';
      });
    }

    /**
     * Check if all data is loaded, then navigate.
     */
    hideIndicatorAndNavigate() {
      if (!(this.userInfoIsLoading || this.companyInfoIsLoading || this.invoicesAreLoading)) {
        this.indicator.hide();
        if (this.data.me.getValue().company == null) {

          this.routerExtension.navigate(
            ['/choose-company']
          );
        } else {

          if (this.data.company.getValue().subscription.expired) {
            this.routerExtension.navigate(
              ['/subscription-expired']
            );
          } else {

            this.route.params.subscribe(
              (params) => {
                if (params.redirect) {
                  this.routerExtension.navigate(
                    [params.redirect]
                  );
                } else {
                  this.routerExtension.navigate(
                    ['/account']
                  );
                }
              }
            );
          }
        }
      }
    }
}
