/**
 * User object class with necessary attributes.
 */
import {CompanyModel} from '../enterprise/company/company.model';

export class UserModel {

    company: CompanyModel;
    username: string;
    password: string;
    emailAddress: string;
    firstName: string;
    lastName: string;

    invitations: Array<CompanyModel>;

    constructor(public language: string) {

    }

    allFieldsFilledIn(): boolean {
        return !(this.username === undefined || this.username === '' || this.emailAddress === undefined || this.emailAddress === '' || this.firstName === undefined || this.firstName === '' || this.lastName === undefined || this.lastName === '');
    }
}
