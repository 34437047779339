import {Component, OnInit} from '@angular/core';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {DataService} from '../shared/data.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {UserModel} from '../shared/user/user.model';
import {UserService} from '../shared/user/user.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NgForm} from '@angular/forms';
import {IndicatorService} from '../shared/indicator.service';
import {LanguageService} from '../shared/language.service';

/**
 * Regex expression used to validate email address input.
 */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {

  collapsed = true;
  isAuthenticated = false;

  company: CompanyModel;
  user: UserModel = new UserModel(this.language.get());

  image: SafeUrl = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  selectedFile: File;

  owner: boolean;

  constructor(private data: DataService,
              private companyService: CompanyService,
              private userService: UserService,
              private router: Router,
              private authService: AuthenticationService,
              private sanitizer: DomSanitizer,
              private indicator: IndicatorService,
              public language: LanguageService) {
    this.data.company.subscribe(
      (company) => {
        this.company = company;
        this.data.me.subscribe(
          (user) => {
            this.user = user;
            this.owner = user.username === company.owner.username;
            this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.company.logo);
          }
        );
      }
    );
    this.authService.isAuthenticated().then(
      (result) => this.isAuthenticated = result
    );
  }

  ngOnInit() {
    if (this.company.name === undefined) {
      this.indicator.show();
      this.companyService.getCompany().subscribe(
        (company) => {
          this.company = company;
          this.userService.getUserInfo().subscribe(
            (user) => {
              this.user = user;
              this.owner = user.username === company.owner.username;
              this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.company.logo);
              this.indicator.hide();
            }, () => {
              this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
            });
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size / 1024 / 1024 > 1) {
      this.language.localize('indicator.alert.messageFileTooBig').subscribe((text) => this.indicator.error(text));
    } else {
      this.fileToBase64();
    }
  }

  fileToBase64() {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        const b64: string = reader.result;
        this.company.logo = b64.replace(/^data:.+;base64,/, '');
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.company.logo);

        this.indicator.show();
        this.companyService.updateCompany(this.company.id, this.company).subscribe(() => {
          this.data.company.next(this.company);
          this.indicator.hide();
          this.language.localize('indicator.alert.logoUpdated').subscribe(
            (text) => {
              this.indicator.alert(text);
            }
          );
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
      }
    };

    reader.readAsDataURL(this.selectedFile);
  }

  /**
   * Save function when user hits save button.
   */
  save(form: NgForm) {

    this.indicator.show();

    // One or more of the fields are empty.
    if (!this.allFieldsAreFilledIn() || !this.allAddressFieldsAreFilledIn()) {
      this.language.localize('indicator.alert.messageEmptyFields').subscribe((text) => this.indicator.error(text));
      return;
    }

    // Email address is not a valid email address.
    if (!emailRegex.test(this.company.emailAddress)) {
      this.language.localize('indicator.alert.messageInvalidEmailAddress').subscribe((text) => this.indicator.error(text));
      return;
    }

    this.companyService.updateCompany(this.company.id, this.company).subscribe(() => {
      this.data.company.next(this.company);
      form.form.markAsPristine();
      this.indicator.hide();
      this.language.localize('indicator.alert.messageCompanySaved').subscribe(
        (text) => {
          this.indicator.alert(text);
        }
      );
    }, () => {
      this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
    });
  }

  /**
   * When user taps leave button.
   */
  leaveCompany() {

    this.indicator.show();

    this.companyService.leaveCompany()
      .subscribe(
        () => {
          this.language.localize('indicator.alert.messageLeave').subscribe(
            (text) => {
              this.indicator.alert(text);
              this.router.navigate(['/login']);
            });
          this.indicator.hide();
        },
        () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  signIn() {
    this.router.navigate(['/login']);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  allFieldsAreFilledIn(): boolean {
    return !(this.company.name === undefined || this.company.name === '' || this.company.emailAddress === undefined || this.company.emailAddress === '' || this.company.telephone === undefined || this.company.telephone === '' || this.company.vatCode === undefined || this.company.vatCode === '' || this.company.cocNumber === undefined || this.company.cocNumber === '');
  }

  allAddressFieldsAreFilledIn(): boolean {
    return !(this.company.address.city === undefined || this.company.address.city === '' || this.company.address.country === undefined || this.company.address.country === '' || this.company.address.street === undefined || this.company.address.street === '' || this.company.address.number === undefined || this.company.address.number === '' || this.company.address.postalCode === undefined || this.company.address.postalCode === '');
  }
}
