import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable, pipe} from 'rxjs';
import {EnvironmentManagerService} from '../environment/environment.service';
import {LoginModel} from './login.model';
import {UserModel} from '../user/user.model';
import {TokenService} from './token/token.service';
import {AuthToken} from './token/token.model';

const loginUrl = '/user/login';
const registerUrl = '/user/register';

@Injectable({
    providedIn: 'root'
})
/**
 * Service for actions concerning UserModel objects.
 */
export class AuthenticationService {

    /**
     * Injecting services and initial setup.
     */
    constructor(private http: HttpClient,
                private env: EnvironmentManagerService,
                private tokenService: TokenService) {

        this.baseUrl = env.getUrlBase();
    }

    /**
     * Base URL for making request to the backend.
     */
    baseUrl: string;

    /**
     * Retrieve login model from local storage based on remember me.
     */
    static getRememberLogin(): LoginModel {
        // if (getBoolean('remember-me')) {
        //     return new LoginModel(getString('username'), getString('password'), true);
        // }

        return new LoginModel('', '', false);
    }

    /**
     * Erase JWT token from local storage.
     */
    private eraseToken() {
        this.tokenService.clear();
    }

    /**
     * Login function returning JWT token.
     */
    login(user: LoginModel): Observable<any> {

        return this.http
            .post(this.baseUrl + loginUrl, user, {observe: 'response'})
            .pipe(map(authenticateSuccess.bind(this)));

        function authenticateSuccess(resp) {

            // Extract JWT token. Different from mobile.
            const bearerToken = resp.body.token;

            this.tokenService.set(new AuthToken(resp.body.token), user.rememberMe);
            return bearerToken;
        }
    }

    /**
     * Register function.
     */
    register(user: UserModel): Observable<any> {

        return this.http.post(this.baseUrl + registerUrl, user, {observe: 'response'});
    }

    /**
     * Sign out and erase JWT token.
     */
    signOut() {
        this.eraseToken();
    }

    /**
     * Get JWT token from local storage.
     */
    getToken() {
      return this.tokenService.get();
    }

  isAuthenticated(): Promise<boolean> {
    return this.getToken()
      .pipe(map((token: AuthToken) => token && token.isValid())).toPromise();
  }
}
