import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../shared/authentication/authentication.service';
import {DataService} from '../shared/data.service';
import {CompanyModel} from '../shared/enterprise/company/company.model';
import {IndicatorService} from '../shared/indicator.service';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {UserService} from '../shared/user/user.service';
import {InvoiceModel} from '../shared/document/invoice.model';
import {LanguageService} from '../shared/language.service';

@Component({
  selector: 'app-subscription-information',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss']
})
export class SubscriptionInformationComponent implements OnInit {

  collapsed = true;
  isAuthenticated = false;
  owner = false;

  company: CompanyModel;
  invoices: Array<InvoiceModel>;

  constructor(private router: Router,
              private authService: AuthenticationService,
              private data: DataService,
              private indicator: IndicatorService,
              private companyService: CompanyService,
              private userService: UserService,
              public language: LanguageService) {
    this.data.company.subscribe(
      (company) => {
        this.company = company;
        this.data.me.subscribe(
          (user) => {
            this.owner = user.username === company.owner.username;
          }
        );
      }
    );
    this.data.observableInvoices.subscribe((invoices) => this.invoices = invoices);
    this.authService.isAuthenticated().then(
      (result) => this.isAuthenticated = result
    );
  }

  ngOnInit() {
    if (this.company.name === undefined) {
      this.indicator.show();
      this.companyService.getCompany().subscribe(
        (company) => {
          this.company = company;
          this.userService.getUserInfo().subscribe(
            (user) => {
              this.owner = user.username === company.owner.username;
              this.indicator.hide();
            }, () => {
              this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
            });
        }, () => {
          this.language.localize('indicator.alert.failed').subscribe((text) => this.indicator.error(text));
        });
    }
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  signIn() {
    this.router.navigate(['/login']);
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
}
