import { Component, OnInit } from '@angular/core';
import {CompanyService} from '../shared/enterprise/company/company.service';
import {DataService} from '../shared/data.service';
import {CompanyModel} from '../shared/enterprise/company/company.model';

@Component({
  selector: 'app-register-company-confirmation',
  templateUrl: './register-company-confirmation.component.html',
  styleUrls: ['./register-company-confirmation.component.scss']
})
export class RegisterCompanyConfirmationComponent implements OnInit {

  company: CompanyModel;

  constructor(private companyService: CompanyService,
              private data: DataService) {
    this.data.company.subscribe(
      (company) => {
        this.company = company;
      }
    );
  }

  ngOnInit() {
  }

}
