import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {CompanyInformationComponent} from './company-information/company-information.component';
import {AuthGuard} from './shared/guards/auth-guard.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {PricingComponent} from './pricing/pricing.component';
import {PersonalInformationComponent} from './personal-information/personal-information.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {FinishPaymentComponent} from './finish-payment/finish-payment.component';
import {ContactComponent} from './contact/contact.component';
import {TermsOfServiceComponent} from './terms-of-service/terms-of-service.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {ChooseSubscriptionComponent} from './choose-subscription/choose-subscription.component';
import {OwnerGuard} from './shared/guards/owner-guard.service';
import {PaymentGuard} from './shared/guards/payment-guard.service';
import {NotOwnerComponent} from './not-owner/not-owner.component';
import {SubscriptionInformationComponent} from './subscription-information/subscription-information.component';
import {ChooseCompanyComponent} from './choose-company/choose-company.component';
import {RegisterCompanyComponent} from './register-company/register-company.component';
import {RegisterConfirmationComponent} from './register-confirmation/register-confirmation.component';
import {RegisterCompanyConfirmationComponent} from './register-company-confirmation/register-company-confirmation.component';
import {CompanyUsersComponent} from './company-users/company-users.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {CompanyGuard} from './shared/guards/company-guard.service';
import {NoCompanyGuard} from './shared/guards/no-company-guard.service';
import {SubscriptionExpiredComponent} from './subscription-expired/subscription-expired.component';


const routes: Routes = [
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'account',
    component: PersonalInformationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    component: CompanyInformationComponent,
    canActivate: [AuthGuard, CompanyGuard]
  },
  {
    path: 'users',
    component: CompanyUsersComponent,
    canActivate: [AuthGuard, CompanyGuard]
  },
  {
    path: 'subscription',
    component: SubscriptionInformationComponent,
    canActivate: [AuthGuard, CompanyGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:redirect',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token/:username',
    component: ResetPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'register-confirmation',
    component: RegisterConfirmationComponent
  },
  {
    path: 'choose-company',
    component: ChooseCompanyComponent,
    canActivate: [AuthGuard, NoCompanyGuard]
  },
  {
    path: 'register-company',
    component: RegisterCompanyComponent,
    canActivate: [AuthGuard, NoCompanyGuard]
  },
  {
    path: 'register-company-confirmation',
    component: RegisterCompanyConfirmationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'choose-subscription/:type',
    component: ChooseSubscriptionComponent,
    canActivate: [AuthGuard, OwnerGuard]
  },
  {
    path: 'finish-payment',
    component: FinishPaymentComponent,
    canActivate: [AuthGuard, OwnerGuard, PaymentGuard]
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'subscription-expired',
    component: SubscriptionExpiredComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'not-owner',
    component: NotOwnerComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
