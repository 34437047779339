import {UserModel} from './user/user.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CompanyModel} from './enterprise/company/company.model';
import {AddressModel} from './enterprise/address.model';
import {TagModel} from './document/tag.model';
import {InvoiceModel} from './document/invoice.model';
import {LanguageService} from './language.service';

@Injectable()
export class DataService {

    storage: any;

    me: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(new UserModel(this.language.get()));
    observableMe: Observable<UserModel> = this.me.asObservable();

    company: BehaviorSubject<CompanyModel> = new BehaviorSubject<CompanyModel>(new CompanyModel(new AddressModel()));
    observableCompany: Observable<CompanyModel> = this.company.asObservable();

    invoices: BehaviorSubject<Array<InvoiceModel>> = new BehaviorSubject<Array<InvoiceModel>>(new Array<InvoiceModel>());
    observableInvoices: Observable<Array<InvoiceModel>> = this.invoices.asObservable();

    constructor(private language: LanguageService) { }

    addInvoice(invoice: InvoiceModel) {
        const temporary = this.invoices.getValue();

        temporary.push(invoice);

        this.invoices.next([...temporary]);
    }

    removeInvoice(id: number) {
        let temporary = this.invoices.getValue();

        temporary = temporary.filter((invoice) => invoice.id !== id);

        this.invoices.next([...temporary]);
    }

    updateInvoice(invoice: InvoiceModel) {
        const temporary = this.invoices.getValue();

        temporary.forEach((document) => {
            if (document.id === invoice.id) {
                document.workedHours = invoice.workedHours;
                document.travellingHours = invoice.travellingHours;
                document.kilometers = invoice.kilometers;
                document.overnightStays = invoice.overnightStays;
                document.materials = invoice.materials;

                document.name = invoice.name;
                document.description = invoice.description;
                document.notes = invoice.notes;
                document.finished = invoice.finished;
                document.signature = invoice.signature;
                document.tag = invoice.tag;
                document.customer = invoice.customer;
            }
        });

        this.invoices.next([...temporary]);
    }

    removeRequest(request: UserModel) {
        const temporary = this.company.getValue();

        temporary.requests = temporary.requests.filter((user) => user.username !== request.username);

        this.company.next(temporary);
    }

    removeUser(user: UserModel) {
        const temporary = this.company.getValue();

        temporary.requests = temporary.users.filter((x) => user.username !== x.username);

        this.company.next(temporary);
    }

    removeTag(tag: TagModel) {
        const temporary = this.company.getValue();

        temporary.tags = temporary.tags.filter((x) => x.id !== tag.id);

        this.company.next(temporary);
    }

    addTag(tag: TagModel) {
        const temporary = this.company.getValue();

        temporary.tags.push(tag);

        this.company.next(temporary);
    }
}
