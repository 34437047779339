/**
 * Login object class with necessary attributes.
 */
export class LoginModel {

    constructor(public username: string,
                public password: string,
                public rememberMe: boolean) {

    }
}
