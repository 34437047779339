import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaymentRequestModel} from './payment-request.model';
import {EnvironmentManagerService} from '../environment/environment.service';
import {Observable} from 'rxjs';
import {PaymentLinkModel} from './payment-link.model';
import {PaymentStatusModel} from './payment-status.model';

const requestPayment = '/payment/create';
const requestPaymentStatus = '/payment/status/';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient,
              private env: EnvironmentManagerService) {

  }

  getPaymentUrl(payment: PaymentRequestModel): Observable<PaymentLinkModel> {
    return this.http.post<PaymentLinkModel>(this.env.getUrlBase() + requestPayment, payment);
  }

  getPaymentStatus(id: string): Observable<PaymentStatusModel> {
    return this.http.get<PaymentStatusModel>(this.env.getUrlBase() + requestPaymentStatus + id);
  }
}
