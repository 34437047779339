import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthenticationInterceptor} from './shared/authentication/authentication.interceptor';
import {DataService} from './shared/data.service';
import {FormsModule} from '@angular/forms';
import { CompanyInformationComponent } from './company-information/company-information.component';
import {AuthGuard} from './shared/guards/auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';
import {MatIconModule, MatSnackBarModule} from '@angular/material';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { PricingComponent } from './pricing/pricing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import {
  EllipsisComponent, LOADING_INDICATOR_CONFIG,
  LoadingIndicatorModule
} from '@btapai/ng-loading-indicator';
import {IndicatorService} from './shared/indicator.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FinishPaymentComponent } from './finish-payment/finish-payment.component';
import { ContactComponent } from './contact/contact.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ChooseSubscriptionComponent } from './choose-subscription/choose-subscription.component';
import {OwnerGuard} from './shared/guards/owner-guard.service';
import {PaymentGuard} from './shared/guards/payment-guard.service';
import { NotOwnerComponent } from './not-owner/not-owner.component';
import { SubscriptionInformationComponent } from './subscription-information/subscription-information.component';
import { ChooseCompanyComponent } from './choose-company/choose-company.component';
import { RegisterCompanyComponent } from './register-company/register-company.component';
import { RegisterCompanyConfirmationComponent } from './register-company-confirmation/register-company-confirmation.component';
import { RegisterConfirmationComponent } from './register-confirmation/register-confirmation.component';
import { CompanyUsersComponent } from './company-users/company-users.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {CompanyGuard} from './shared/guards/company-guard.service';
import {NoCompanyGuard} from './shared/guards/no-company-guard.service';
import { SubscriptionExpiredComponent } from './subscription-expired/subscription-expired.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    CompanyInformationComponent,
    NotFoundComponent,
    PricingComponent,
    PersonalInformationComponent,
    ForgotPasswordComponent,
    FinishPaymentComponent,
    ContactComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    ChooseSubscriptionComponent,
    NotOwnerComponent,
    SubscriptionInformationComponent,
    ChooseCompanyComponent,
    RegisterCompanyComponent,
    RegisterCompanyConfirmationComponent,
    RegisterConfirmationComponent,
    CompanyUsersComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    SubscriptionExpiredComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    AngularSvgIconModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    LoadingIndicatorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: LOADING_INDICATOR_CONFIG,
      useValue: {color: '#007bff', size: 160, indicatorComponent: EllipsisComponent}
    },
    DataService,
    IndicatorService,
    AuthGuard,
    OwnerGuard,
    PaymentGuard,
    CompanyGuard,
    NoCompanyGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

